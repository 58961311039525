<template>
  <div class="back">
    <svg class="back__svg">
      <clipPath id="my-clip-path" clipPathUnits="objectBoundingBox">
        <path
          d="M1,0.429 H0.048 v0.143 H1 M0.214,0.9 L0.036,0.4,0,0.5 l0.179,0.5 M0.179,0 L0,0.5 l0.036,0.1 L0.214,0.1"
        ></path>
      </clipPath>
    </svg>

    <div class="back__clipped"></div>
  </div>
</template>

<script>
export default {
  name: 'ArrowBack',
}
</script>

<style lang="scss" scoped>
.back {
  width: 60px;
  height: 20px;

  &__svg {
    position: absolute;
    width: 0;
    height: 0;
  }

  &__clipped {
    width: 100%;
    height: 20px;
    background-color: #ffffff;
    -webkit-clip-path: url(#my-clip-path);
    clip-path: url(#my-clip-path);

    transition: 0.2s;
    background-size: 200% auto;
    background-repeat: no-repeat;
    background-position: left center;
    background-image: linear-gradient(91.89deg, #fff 0%, #fff 50%, #ea47ac 50%, #fea7af 100%);
  }
}

.back:hover,
.back:focus {
  .back__clipped {
    background-position: right center;
  }
}
</style>