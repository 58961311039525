<template>
  <div class="d-flex flex-column slider p-2" :class="{ left: hand === 'left' }">
    <div class="d-flex flex-column slide">
      <div class="title-container">
        <p class="h1 text-center">{{ $t('slideTitle') }} "{{ slides[index].char }}"  {{ percent_com }}</p>
      </div>
      <div class="d-flex justify-content-center align-items-center" v-for="(slide, i) in slides" :key="i">
        <transition name="fade">
          <div class="picture-container" v-if="i == index">
            <picture class="d-flex justify-content-center align-items-center picture">
              <img :src="require(`../assets/images/${slide.img}.png`)" :alt="slide.char" />
            </picture>
          </div>
        </transition>
      </div>
      <div class="d-flex justify-content-center buttons-container">
        <button v-if="index" class="btn button" type="button" data-template="prev" @click="slideChangeHandler">
          {{ $t('slidePrev') }}
        </button>
        <button
            v-if="index < slides.length - 1"
            class="btn button button_inverse"
            type="button"
            data-template="next"
            @click="slideChangeHandler"
        >
          {{ $t('slideNext') }}
        </button>
        <button
          v-if="index == slides.length - 1"
          class="btn button button_inverse"
          type="button"
          data-template="reset"
          @click="slideChangeHandler"
        >
          {{ $t('slideRestart') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getterTypes } from '@/store/modules/hand'

export default {
  name: 'Slider',
  props: {
    percent: {
      required: false,
      default: 0,
    }
  },
  data() {
    return {
      index: 0,
      slides: [
        { char: 'A', img: 'A' },
        { char: 'B', img: 'B' },
        { char: 'C', img: 'C' },
        { char: 'D', img: 'D' },
        { char: 'G', img: 'G' },
        { char: 'I', img: 'I' },
        { char: 'R', img: 'R' },
        { char: 'V', img: 'V' },
        // { char: 'W', img: 'W' },
        { char: 'Y', img: 'Y' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      hand: getterTypes.getHand,
    }),
    percent_com () {
      return '' + +this.percent.toFixed(2).toString().replace('0.', '') + '%'
    }
  },

  methods: {
    slideChangeHandler(event) {
      const t = event.target.dataset.template
      if (t === 'next') {
        this.index++
      }
      if (t === 'prev') {
        this.index--
      }
      if (t === 'reset') {
        this.index = 0
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.slider {
  width: 100%;
}

.slide {
  position: relative;
  flex: 1;
}

.title-container {
  @media screen and (min-width: 992px) {
    margin-top: 200px;
    margin-bottom: auto;
  }
}

.slider.left .title-container {
  direction: ltr;
}

.slide {
  position: relative;
  width: 100%;
  flex-grow: 1;
}

.picture {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  transform: translate(-50%, -50%);

  img {
    width: 50%;
    height: auto;

    @media screen and (min-width: 992px) {
      width: 75%;
    }
  }
}

.buttons-container {
  margin: auto auto 0;
  grid-gap: 0.5rem;

  width: 100%;
  max-width: 618px;

  @media screen and (min-width: 992px) {
    margin: auto auto 115px;
    grid-gap: 40px;
  }
}

.slider.left .buttons-container {
  direction: ltr;
}

.button {
  width: 100%;
  @media screen and (min-width: 992px) {
    max-width: 292px;
  }
}
</style>
