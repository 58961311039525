<template>
  <div class="d-flex flex-column align-items-center p-2">
    <logo class="mx-auto" />
    <div class="text-container d-flex flex-column">
      <p class="text-center">Выберите язык для дальнейшего пользования</p>
      <p class="text-center">Select a language for further use</p>
    </div>

    <div class="d-flex flex-wrap justify-content-center label-container">
      <label class="lang-label">
        <input
          class="visually-hidden"
          type="radio"
          data-lang="ru"
          name="lang"
          :checked="currentLocal === 'ru'"
          @click="selectLocale"
        />
        <div class="d-flex align-items-center">
          <svg width="24" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect
              x=".272"
              y=".772"
              width="23.456"
              height="16.456"
              rx="1.902"
              fill="#fff"
              stroke="#F5F5F5"
              stroke-width=".544"
            />
            <mask id="a" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="18">
              <rect
                x=".272"
                y=".772"
                width="23.456"
                height="16.456"
                rx="1.902"
                fill="#fff"
                stroke="#fff"
                stroke-width=".544"
              />
            </mask>
            <g mask="url(#a)" fill-rule="evenodd" clip-rule="evenodd">
              <path d="M0 11.833h24V6.166H0v5.667z" fill="#0C47B7" />
              <path d="M0 17.5h24v-5.666H0V17.5z" fill="#E53B35" />
            </g>
          </svg>
          <span>Русский</span>
        </div>
      </label>

      <label class="lang-label">
        <input
          class="visually-hidden"
          type="radio"
          data-lang="en"
          name="lang"
          :checked="currentLocal === 'en'"
          @click="selectLocale"
        />
        <div class="d-flex align-items-center">
          <svg width="24" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y=".5" width="24" height="17" rx="2" fill="#fff" />
            <mask id="a" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="18">
              <rect y=".5" width="24" height="17" rx="2" fill="#fff" />
            </mask>
            <g mask="url(#a)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M24 .5H0v1.133h24V.5zm0 2.267H0V3.9h24V2.767zM0 5.033h24v1.134H0V5.033zM24 7.3H0v1.133h24V7.3zM0 9.567h24V10.7H0V9.567zm24 2.266H0v1.134h24v-1.134zM0 14.1h24v1.133H0V14.1zm24 2.267H0V17.5h24v-1.133z"
                fill="#D02F44"
              />
              <path fill="#46467F" d="M0 .5h10.286v7.933H0z" />
              <g filter="url(#b)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2.286 2.2a.57.57 0 0 1-.572.567.57.57 0 0 1-.571-.567.57.57 0 0 1 .571-.567.57.57 0 0 1 .572.567zm2.285 0A.57.57 0 0 1 4 2.767a.57.57 0 0 1-.571-.567A.57.57 0 0 1 4 1.633a.57.57 0 0 1 .571.567zm1.715.567a.57.57 0 0 0 .571-.567.57.57 0 0 0-.571-.567.57.57 0 0 0-.572.567.57.57 0 0 0 .572.567zM9.143 2.2a.57.57 0 0 1-.572.567A.57.57 0 0 1 8 2.2a.57.57 0 0 1 .571-.567.57.57 0 0 1 .572.567zM2.857 3.9a.57.57 0 0 0 .572-.567.57.57 0 0 0-.572-.566.57.57 0 0 0-.571.566.57.57 0 0 0 .571.567zm2.857-.567a.57.57 0 0 1-.571.567.57.57 0 0 1-.572-.567.57.57 0 0 1 .572-.566.57.57 0 0 1 .571.566zM7.43 3.9A.57.57 0 0 0 8 3.333a.57.57 0 0 0-.571-.566.57.57 0 0 0-.572.566.57.57 0 0 0 .572.567zm1.714.567a.57.57 0 0 1-.572.566A.57.57 0 0 1 8 4.467a.57.57 0 0 1 .571-.567.57.57 0 0 1 .572.567zm-2.857.566a.57.57 0 0 0 .571-.566.57.57 0 0 0-.571-.567.57.57 0 0 0-.572.567.57.57 0 0 0 .572.566zM4.57 4.467A.57.57 0 0 1 4 5.033a.57.57 0 0 1-.571-.566A.57.57 0 0 1 4 3.9a.57.57 0 0 1 .571.567zm-2.857.566a.57.57 0 0 0 .572-.566.57.57 0 0 0-.572-.567.57.57 0 0 0-.571.567.57.57 0 0 0 .571.566zM3.43 5.6a.57.57 0 0 1-.572.567.57.57 0 0 1-.571-.567.57.57 0 0 1 .571-.567.57.57 0 0 1 .572.567zm1.714.567a.57.57 0 0 0 .571-.567.57.57 0 0 0-.571-.567.57.57 0 0 0-.572.567.57.57 0 0 0 .572.567zM8 5.6a.57.57 0 0 1-.571.567.57.57 0 0 1-.572-.567.57.57 0 0 1 .572-.567A.57.57 0 0 1 8 5.6zm.571 1.7a.57.57 0 0 0 .572-.567.57.57 0 0 0-.572-.566.57.57 0 0 0-.571.566.57.57 0 0 0 .571.567zm-1.714-.567a.57.57 0 0 1-.571.567.57.57 0 0 1-.572-.567.57.57 0 0 1 .572-.566.57.57 0 0 1 .571.566zM4 7.3a.57.57 0 0 0 .571-.567A.57.57 0 0 0 4 6.167a.57.57 0 0 0-.571.566A.57.57 0 0 0 4 7.3zm-1.714-.567a.57.57 0 0 1-.572.567.57.57 0 0 1-.571-.567.57.57 0 0 1 .571-.566.57.57 0 0 1 .572.566z"
                  fill="url(#c)"
                />
              </g>
            </g>
            <defs>
              <linearGradient id="c" x1="1.143" y1="1.633" x2="1.143" y2="7.3" gradientUnits="userSpaceOnUse">
                <stop stop-color="#fff" />
                <stop offset="1" stop-color="#F0F0F0" />
              </linearGradient>
              <filter
                id="b"
                x="1.143"
                y="1.633"
                width="8"
                height="6.667"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy="1" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_501_1531" />
                <feBlend in="SourceGraphic" in2="effect1_dropShadow_501_1531" result="shape" />
              </filter>
            </defs>
          </svg>
          <span>English</span>
        </div>
      </label>
    </div>

    <router-link class="btn button" :to="{ name: 'load' }">{{ $t('langBtn') }}</router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getterTypes, actionTypes } from '@/store/modules/locale'

import Logo from '@/components/Logo'
export default {
  name: 'Lang',
  components: {
    Logo,
  },
  computed: {
    ...mapGetters({
      currentLocal: getterTypes.getLocale,
    }),
  },
  methods: {
    selectLocale(event) {
      const lang = event.currentTarget.dataset.lang
      this.$store.dispatch(actionTypes.setLocale, lang)
    },
  },
}
</script>

<style lang="scss" scoped>
.text-container {
  grid-gap: 20px;
  margin-bottom: 40px;
}

.label-container {
  grid-gap: 20px;
  margin-bottom: 40px;
}

.lang-label {
  position: relative;
  cursor: pointer;

  div {
    display: flex;
    grid-gap: 8px;
    border-radius: 8px;
    border: 4px solid #00c0ef;
    padding: 8px 16px;
    transition: 0.2s ease;
  }
}

.lang-label input:checked + div {
  background-color: #00c0ef;
}

.button {
  width: 100%;
  max-width: 386px;
}
</style>