<template>
  <div class="d-flex flex-column align-items-center p-2">
    <logo class="mx-auto" />
    <div class="text-container">
      <p class="text-center">{{ $t('homeMsg') }}</p>
    </div>
    <router-link class="btn button" :to="{ name: 'lang' }">{{ $t('homeBtn') }}</router-link>
  </div>
</template>

<script>
import Logo from '@/components/Logo'
export default {
  name: 'Home',
  components: {
    Logo,
  },
}
</script>

<style lang="scss" scoped>
.text-container {
  margin-bottom: 160px;
}

.button {
  width: 100%;
  max-width: 386px;
}
</style>
