<template>
  <div class="d-flex flex-column justify-content-center align-items-center spec p-2">
    <router-link class="back" :to="{ name: 'approval' }">
      <arrow-back />
    </router-link>
    <logo class="mx-auto" />
    <div class="text-container mx-auto">
      <p class="text-center">
        {{ $t('specMsg') }}
      </p>
    </div>
    <div class="title-container">
      <h1 class="h2 text-center">{{ $t('specTitle') }}</h1>
    </div>
    <div class="d-flex flex-column flex-md-row justify-content-center align-items-center circle-container">
      <div class="circle">
        <div class="rocket">
          <svg width="42" height="48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M32.756 23.905c-.703-3.154-1.894-6.189-3.76-8.738-3.526-4.841-9.243-7.31-14.79-9.417a1.719 1.719 0 0 0-2.219.996C9.791 12.53 7.921 18.2 9.191 24.056c.67 3.09 2.147 5.997 4.036 8.614-1.283 4.106 1.736 12.227 1.736 12.227s4.969-2.788 6.538-4.038c.958.694 1.904 1.33 2.813 1.867a1.736 1.736 0 0 0 1.583.092l6.021-2.71c.42-.186.74-.533.906-.957.031-.09.438-2.45.552-3.628 1.98-.343 7.367-2.202 7.367-2.202s-4.064-7.651-7.987-9.416z"
              fill="#fff"
            />
            <path d="M21.22 24.166a2.99 2.99 0 1 1-2.45-5.454 2.99 2.99 0 0 1 2.45 5.454z" fill="#3853EC" />
          </svg>
        </div>
        <div class="circle-block">
          <span class="meta text-center">{{ $t('specC1') }}</span>
        </div>
      </div>
      <div class="circle">
        <div class="rocket">
          <svg width="42" height="48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M32.756 23.905c-.703-3.154-1.894-6.189-3.76-8.738-3.526-4.841-9.243-7.31-14.79-9.417a1.719 1.719 0 0 0-2.219.996C9.791 12.53 7.921 18.2 9.191 24.056c.67 3.09 2.147 5.997 4.036 8.614-1.283 4.106 1.736 12.227 1.736 12.227s4.969-2.788 6.538-4.038c.958.694 1.904 1.33 2.813 1.867a1.736 1.736 0 0 0 1.583.092l6.021-2.71c.42-.186.74-.533.906-.957.031-.09.438-2.45.552-3.628 1.98-.343 7.367-2.202 7.367-2.202s-4.064-7.651-7.987-9.416z"
              fill="#fff"
            />
            <path d="M21.22 24.166a2.99 2.99 0 1 1-2.45-5.454 2.99 2.99 0 0 1 2.45 5.454z" fill="#3853EC" />
          </svg>
        </div>
        <div class="circle-block">
          <span class="meta text-center">{{ $t('specC2') }}</span>
        </div>
      </div>
      <div class="circle">
        <div class="rocket">
          <svg width="42" height="48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M32.756 23.905c-.703-3.154-1.894-6.189-3.76-8.738-3.526-4.841-9.243-7.31-14.79-9.417a1.719 1.719 0 0 0-2.219.996C9.791 12.53 7.921 18.2 9.191 24.056c.67 3.09 2.147 5.997 4.036 8.614-1.283 4.106 1.736 12.227 1.736 12.227s4.969-2.788 6.538-4.038c.958.694 1.904 1.33 2.813 1.867a1.736 1.736 0 0 0 1.583.092l6.021-2.71c.42-.186.74-.533.906-.957.031-.09.438-2.45.552-3.628 1.98-.343 7.367-2.202 7.367-2.202s-4.064-7.651-7.987-9.416z"
              fill="#fff"
            />
            <path d="M21.22 24.166a2.99 2.99 0 1 1-2.45-5.454 2.99 2.99 0 0 1 2.45 5.454z" fill="#3853EC" />
          </svg>
        </div>
        <div class="circle-block">
          <span class="meta text-center">{{ $t('specC3') }}</span>
        </div>
      </div>
    </div>

    <div class="spec__video-link" @click="video_open=true">{{ $t('video-demo') }}</div>
    <div class="title-container">
      <h2 class="h4 text-center">{{ $t('specSubTitle') }}</h2>
    </div>

    <router-link class="btn button" :to="{ name: 'playground' }">{{ $t('specBtn') }}</router-link>
    <div class="spec__text">{{ $t('video-delay') }}</div>
    <VideoModal url="demo.html" :is-open="video_open" @update:isOpen="val => video_open=val"></VideoModal>
  </div>
</template>

<script>
import ArrowBack from '@/components/ArrowBack.vue'
import Logo from '@/components/Logo'
import VideoModal from '@/components/VideoModal'
export default {
  name: 'Spec',
  data: () => {
    return {
      video_open: false,
    }
  },
  components: {
    ArrowBack,
    Logo,
    VideoModal,
  },
}
</script>

<style lang="scss" scoped>

.spec__video-link {
  color: #FEA7AF;
  font-weight: bold;
  font-size: 24px;
  padding-bottom: 20px;
  cursor: pointer;
  text-decoration: underline;
}
.spec__text {
  font-size: 14px;
  text-align: center;
  color: #171655;
}
.back {
  position: absolute;
  top: 15px;
  left: 15px;

  @media screen and (min-width: 768px) {
    top: 20px;
    left: 20px;
  }
}

.spec {
  padding-top: 40px;

  @media screen and (min-width: 768px) {
    padding-top: 0;
  }
}

.text-container {
  max-width: 1100px;
  margin-bottom: 40px;
}

.title-container {
  margin-bottom: 40px;
}

.button {
  width: 100%;
  max-width: 386px;
}

.circle-container {
  margin-bottom: 40px;
  grid-gap: 32px;

  @media (min-width: 992px) {
    grid-gap: 66px;
  }
}

.circle {
  position: relative;
  display: flex;
  border: 4px solid transparent;
  border-radius: 50%;
  max-width: 272px;
  width: 100%;
  background-origin: border-box;
  background-repeat: no-repeat;
  background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 1));

  &::before {
    content: '';
    padding-top: 100%;
  }
}

.circle:nth-child(2) {
  background-image: linear-gradient(15deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 1));
}

.circle:nth-child(3) {
  background-image: linear-gradient(100deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 1));
}

.circle-block {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 40px;
  width: 100%;
  min-height: 100%;
  background-color: #4340ed;
}

.rocket {
  position: absolute;
  z-index: 10;
}

.circle:nth-child(1) .rocket {
  top: calc(50% + 24px);
  left: -12px;
}

.circle:nth-child(2) .rocket {
  top: 32px;
  left: 0;
  transform: rotate(60deg);
}

.circle:nth-child(3) .rocket {
  top: 0;
  right: 32px;
  transform: rotate(-205deg);
}
</style>
