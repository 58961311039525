<template>
  <div class="logo">
    <svg width="519" height="177" viewBox="0 0 519 177" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M76.0704 98.5716C79.2218 99.9895 86.8312 101.505 90.8971 95.3177C95.5922 88.1731 92.3787 75.9798 100.269 76.2888C106.153 76.5188 103.615 91.3 102.048 98.1476C100.506 104.886 87.6836 124.841 67.8607 122.573C47.1503 120.203 46.337 94.9658 49.5294 83.6634C52.6908 72.471 61.3267 62.2686 67.9857 56.4319C75.4891 49.8553 96.2525 41.3338 97.8792 48.1104C99.4599 54.694 85.7517 57.1069 80.1702 61.5026C76.1524 64.6665 67.4465 72.339 67.5775 81.5565C67.7246 91.8299 70.6539 96.1357 76.0704 98.5716Z"
        fill="#FFCC00"
      />
      <path
        d="M91.3663 57.8005C87.5756 59.5934 84.5232 60.7004 81.812 62.7313C77.9052 65.6581 69.4204 72.7787 69.3343 81.5782C69.3323 81.7952 69.3373 81.9912 69.3373 82.2012C72.4808 76.9705 77.9072 73.1357 80.8515 71.2648C86.5751 67.627 98.0603 66.735 97.1929 60.3304C96.9018 58.1835 94.7038 57.7145 91.3663 57.8005Z"
        fill="#FFCC00"
      />
      <path
        d="M141.169 71.9238H146.54C153.242 71.9238 160.387 75.5196 160.387 82.5132C160.387 84.7301 158.565 85.1731 157.923 85.1731H155.066C153.686 85.1731 152.553 84.5321 151.616 82.5132C150.533 80.1994 148.66 78.7204 145.704 78.7204H141.417C134.321 78.7204 132.399 81.3313 132.399 89.3598C132.399 97.3884 134.321 99.9982 141.417 99.9982H146.196C149.152 99.9982 151.025 98.5203 152.11 96.2065C153.046 94.1866 154.179 93.5466 155.558 93.5466H158.417C159.057 93.5466 160.881 93.9896 160.881 96.2065C160.881 103.2 153.734 106.796 147.033 106.796H141.169C129.392 106.796 123.331 101.328 123.331 89.3598C123.331 77.3915 129.392 71.9238 141.169 71.9238Z"
        fill="#E1EDFF"
      />
      <path
        d="M171.868 99.3576C173.198 99.3576 174.48 99.8006 175.908 99.8006H176.402C179.555 99.8006 181.772 97.7317 182.956 93.5449H180.886C172.509 93.5449 168.714 90.1471 165.61 83.0545L161.815 74.386C161.273 73.1051 161.914 72.1201 163.146 72.1201H168.221C169.7 72.1201 170.931 73.0561 171.423 74.287L174.184 80.9846C175.711 84.6304 177.928 86.7483 182.166 86.7483H185.122L189.459 74.336C189.952 72.9081 191.232 72.1201 192.465 72.1201H197.344C198.575 72.1201 199.266 73.1041 198.871 74.287L191.036 96.7457C189.015 102.558 185.221 106.597 176.893 106.597H171.077C169.797 106.597 168.81 105.563 168.81 104.331V101.622C168.81 100.342 169.796 99.3566 171.077 99.3566H171.868V99.3576Z"
        fill="#E1EDFF"
      />
      <path
        d="M223.558 72.1211C232.724 72.1211 238.095 76.2579 238.095 85.5173C238.095 94.7768 232.724 98.9136 223.558 98.9136H212.028V104.331C212.028 105.562 211.042 106.597 209.761 106.597H205.228C203.996 106.597 202.961 105.563 202.961 104.331V74.387C202.961 73.106 203.945 72.1211 205.178 72.1211H223.558ZM212.027 78.9177V92.117H220.799C225.53 92.117 229.028 91.133 229.028 85.5173C229.028 79.9017 225.53 78.9167 220.799 78.9167L212.027 78.9177Z"
        fill="#E1EDFF"
      />
      <path
        d="M245.625 81.4565C247.497 73.7729 254.248 72 261.245 72H274.255C275.486 72 276.522 72.9839 276.522 74.2159V104.26C276.522 105.491 275.487 106.477 274.255 106.477H239.267C238.035 106.477 237 105.443 237 104.211V101.896C237 100.665 238.034 99.6804 239.267 99.6804H241.14L245.625 81.4565ZM250.404 99.6804H267.454V78.7966H260.259C257.204 78.7966 255.135 80.6185 254.445 83.5254L250.404 99.6804Z"
        fill="#E1EDFF"
      />
      <path
        d="M300.43 71.3823C312.748 71.3823 319.795 76.554 319.795 89.3603C319.795 102.167 312.748 107.338 300.43 107.338C288.111 107.338 281.064 102.167 281.064 89.3603C281.064 76.554 288.11 71.3823 300.43 71.3823ZM290.131 89.3593C290.131 96.7469 293.037 100.836 300.43 100.836C307.822 100.836 310.728 96.7469 310.728 89.3593C310.728 81.9707 307.822 77.883 300.43 77.883C293.037 77.883 290.131 81.9717 290.131 89.3593Z"
        fill="#E1EDFF"
      />
      <path
        d="M355.766 106.597H351.183C349.952 106.597 348.915 105.612 348.915 104.331V78.9177H343.052C339.947 78.9177 337.484 80.7896 336.695 83.7444L331.175 104.332C330.83 105.612 329.599 106.598 328.318 106.598H323.439C322.355 106.598 321.567 105.662 321.862 104.578L327.973 81.4786C329.993 73.844 336.941 72.1211 343.938 72.1211H355.963C357.096 72.1211 357.984 73.204 357.984 74.387V104.332C357.984 105.612 356.998 106.597 355.766 106.597Z"
        fill="#E1EDFF"
      />
      <path
        d="M368.083 81.4786C370.104 73.844 377.052 72.1211 384.049 72.1211H397.009C398.192 72.1211 399.078 73.155 399.078 74.337V104.381C399.078 105.612 398.044 106.598 396.811 106.598H392.278C390.996 106.598 390.011 105.613 390.011 104.332V98.2736H372.911L371.286 104.332C370.939 105.612 369.709 106.598 368.427 106.598H363.696C362.416 106.598 361.675 105.613 362.02 104.381L368.083 81.4786ZM374.686 91.477H390.011V78.9177H383.161C380.055 78.9177 377.593 80.7896 376.804 83.7444L374.686 91.477Z"
        fill="#E1EDFF"
      />
      <path
        d="M434.557 106.598C433.325 106.598 432.29 105.564 432.29 104.332V82.0214L419.724 104.333C419.035 105.564 417.506 106.599 416.226 106.599H408.243C406.962 106.599 405.976 105.565 405.976 104.333V74.3878C405.976 73.1069 406.961 72.1219 408.243 72.1219H412.48C413.712 72.1219 414.747 73.1059 414.747 74.3378V98.4224L428.446 74.3878C429.136 73.2049 430.565 72.1219 431.748 72.1219H438.844C440.075 72.1219 441.062 73.1559 441.062 74.3878V104.333C441.062 105.564 440.027 106.599 438.795 106.599H434.557V106.598ZM426.623 62.2705C426.92 61.5325 427.511 61.0396 428.299 61.0396H430.27C431.108 61.0396 431.601 61.3845 431.601 62.2705C431.601 66.7042 428.348 69.4131 423.519 69.4131C418.691 69.4131 415.438 66.7042 415.438 62.2705C415.438 61.3835 415.931 61.0396 416.768 61.0396H418.74C419.528 61.0396 420.119 61.5325 420.416 62.2705C420.859 63.3544 421.845 63.9954 423.52 63.9954C425.195 63.9954 426.18 63.3544 426.623 62.2705Z"
        fill="#E1EDFF"
      />
      <path
        d="M477.821 72.1211C479.102 72.1211 480.089 73.1051 480.089 74.387V76.6529C480.089 77.9328 479.103 78.9187 477.821 78.9187H467.079V104.382C467.079 105.613 466.044 106.599 464.812 106.599H460.279C459.047 106.599 458.012 105.614 458.012 104.382V78.9177H447.27C445.988 78.9177 445.002 77.9328 445.002 76.6519V74.386C445.002 73.1051 445.987 72.1201 447.27 72.1201H477.821V72.1211Z"
        fill="#E1EDFF"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'Logo',
}
</script>

<style lang="scss" scoped>
.logo {
  svg {
    width: 100%;
    height: auto;
  }
}
</style>