<template>
  <div class="recommendations d-flex flex-column justify-content-center align-items-center wrapper">
    <h2 class="h2 text-center">{{ $t('recommendationsTitle') }}</h2>
    <div class="blocks d-grid">
      <div class="block d-grid">
        <h3 class="h4">{{ $t('recommendationsB1Title') }}</h3>
        <p class="meta">{{ $t('from') }} 0,3 м {{ $t('to') }} 1 м – 90%</p>
        <p class="meta">{{ $t('from') }} 1м {{ $t('to') }} 3м – 70%</p>
        <p class="meta">{{ $t('from') }} 3м {{ $t('to') }} 5м – 50%</p>
      </div>
      <div class="block d-grid">
        <h3 class="h4">{{ $t('recommendationsB2Title') }}</h3>
        <p class="meta">416 – 70%</p>
        <p class="meta">640 – 90%</p>
      </div>
      <div class="block d-grid">
        <h3 class="h4">{{ $t('recommendationsB3Title') }}</h3>
        <p class="meta">{{ $t('less') }} 15 {{ $t('lc') }} – 20%</p>
        <p class="meta">15-25 {{ $t('lc') }} – 60%</p>
        <p class="meta">25-100 {{ $t('lc') }} – от 75%</p>
      </div>

      <div class="block d-grid">
        <h3 class="h4">{{ $t('Задержка') }}</h3>
        <p class="meta"> В данной версии видео поток передаётся через Webrtc, по этому существует задержка изображения ~ 2 сек.</p>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from '@/components/Logo'

export default {
  name: 'Recommendations',
  components: {
    Logo,
  },
}
</script>

<style lang="scss" scoped>
.recommendations {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 450px;
  z-index: 30;
  overflow: hidden;
  overflow-y: auto;
  background-color: #4340ed;

  @media (min-width: 768px) {
    padding-top: 40px;
  }

  @media (min-width: 992px) {
    position: absolute;
    width: 50%;
  }
}

.recommendations.left {
  direction: ltr;
  @media (min-width: 892px) {
    left: 50%;
  }
}

.blocks {
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    padding: 5%;
    gap: 44px;
  }
}

.block {
  position: relative;
  gap: 8px;
  padding: 44px;
  min-height: 249px;

  &:nth-child(1),
  &:nth-child(2) {
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 8px;
      background-color: #1b8bed;

      @media (min-width: 768px) {
        width: 252px;
      }
    }
  }

  &:nth-child(1) {
    &::after {
      @media (min-width: 768px) {
        bottom: -26px;
        right: 26px;
      }
    }
  }

  &:nth-child(2) {
    &::after {
      @media (min-width: 768px) {
        bottom: -26px;
        left: 26px;
      }
    }
  }

  &:nth-child(2) {
    @media (min-width: 768px) {
      &:before {
        content: '';
        position: absolute;
        bottom: 26px;
        left: -26px;
        width: 8px;
        height: 252px;
        background-color: #1b8bed;
      }
    }
  }

  &:nth-child(3) {
    @media (min-width: 768px) {
      &:before {
        content: '';
        position: absolute;
        top: 26px;
        right: -26px;
        width: 8px;
        height: 252px;
        background-color: #1b8bed;
      }
    }
  }

  &:nth-child(4) {
    display: none !important;

    @media (min-width: 768px) {
      display: grid !important;
    }
  }
}
</style>
